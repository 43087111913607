import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts";
import * as ChartUtils from "../utils/chart_utils";

const SERVICE = "All Services";
const HEIGHT = 230;


// Connects to data-controller="current-chart"
export default class extends Controller {
  static targets = ["chart", "service"]
  static values = {
    currentServicesCosts: Array,
    lastServicesCosts: Array,
  }

  connect() {
    this.currentServicesCostsValue = ChartUtils.restructureServicesCosts(this.currentServicesCostsValue);
    this.lastServicesCostsValue = ChartUtils.restructureServicesCosts(this.lastServicesCostsValue);

    this.activeService = SERVICE;
    this.#switchSelectedServiceColor(this.serviceTargets[0]);

    this.#renderChart();
  }

  updateService(event) {
    this.activeService = event.currentTarget.dataset.service;
    this.#switchSelectedServiceColor(event.currentTarget);

    this.#renderChart();
  }

  #switchSelectedServiceColor(targetElement) {
    this.serviceTargets.forEach(serviceElement => {
      serviceElement.classList.toggle("bg-gray-700", serviceElement === targetElement);
    });
  }

  #renderChart() {
    this.chartTarget.innerHTML = "";
    new ApexCharts(this.chartTarget, this.#getChartOptions()).render();
  }

  #getChartOptions() {
    const { costs: currentCosts, categories: currentCategories } = this.#getFilteredData(this.currentServicesCostsValue);
    const { costs: lastCosts, categories: lastCategories } = this.#getFilteredData(this.lastServicesCostsValue);
    this.currentCategories = currentCategories;
    return {
      series: this.#getSeriesConfig(lastCosts, currentCosts),
      chart: this.#getChartConfig(),
      xaxis: this.#getXAxisConfig(lastCategories),
      yaxis: this.#getYAxisConfig([...currentCosts, ...lastCosts]),
      dataLabels: { enabled: false },
      stroke: {
        curve: "smooth",
        width: 1,
        lineCap: "round",
      },
      grid: { show: false },
      tooltip: {
        followCursor: true,
        custom: ({ series, _, dataPointIndex, w }) => {
          const lastCost = ChartUtils.formatCurrency(series[0][dataPointIndex]);
          const lastDate = w.globals.categoryLabels[dataPointIndex];
          if (dataPointIndex >= this.currentCategories.length) {
            return `
              <div class="flex gap-4 py-1 px-2 border bg-bg-dark border-border-dark text-xxs">
                <div class="flex gap-2">
                  <span class="my-auto rounded-full size-[5px] bg-gray"></span>
                  <span>${lastCost}</span>
                </div>
                  <span class="text-gray">${lastDate}</span>
              </div>
            `;
          }
          const currentCost = ChartUtils.formatCurrency(series[1][dataPointIndex]);
          const currentDate = this.currentCategories[dataPointIndex];
          return `
            <div class="flex gap-4 py-1 px-2 border bg-bg-dark border-border-dark text-xxs">
              <div class="flex flex-col flex-1 gap-2">
                <div class="flex gap-2">
                  <span class="my-auto rounded-full size-[5px] bg-primary-600"></span>
                  <span>${currentCost}</span>
                </div>
                <div class="flex gap-2">
                  <span class="my-auto rounded-full size-[5px] bg-gray"></span>
                  <span>${lastCost}</span>
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <span class="text-gray">${currentDate}</span>
                <span class="text-gray">${lastDate}</span>
              </div>
            </div>
          `;
        },
      },
      colors: [ChartUtils.getTailwind("gray"), ChartUtils.getTailwind("primary-600")],
      markers: {
        strokeWidth: 0,
        hover: { size: 0 },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.75,
          stops: [0, 60],
        },
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "right",
        showForSingleSeries: true,
        fontSize: ChartUtils.getTailwind("text-xs"),
        labels: { useSeriesColors: true },
        markers: {
          strokeWidth: 0,
          size: 2.5,
          offsetX: -6,
        },
        itemMargin: { horizontal: 15 },
        onItemClick: { toggleDataSeries: false },
        onItemHover: { highlightDataSeries: false },
      },
    };
  }

  #getFilteredData(costValue) {
    let costs = costValue.map(item => item.costs[this.activeService] || 0);
    let categories = costValue.map(item => item.date);
    return { costs, categories };
  }

  #getSeriesConfig(lastCosts, currentCosts) {
    return [
      {
        name: "Last Month",
        data: lastCosts,
      },
      {
        name: "Current Month",
        data: currentCosts,
      },
    ];
  }

  #getChartConfig() {
    return {
      type: "line",
      fontFamily: "Geist Sans, Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
      height: HEIGHT,
      parentHeightOffset: 0,
      toolbar: { show: false },
      animations: { enabled: false },
      zoom: { enabled: false },
    };
  }

  #getXAxisConfig(categories) {
    return {
      categories: categories,
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
      crosshairs: {
        width: 0.5,
        stroke: { width: 0 },
        fill: {
          type: "gradient",
          gradient: {
            colorTo: ChartUtils.getTailwind("white"),
            colorFrom: ChartUtils.getTailwind("white"),
            stops: [0, 50, 50, 100],
            opacityFrom: 0.2,
            opacityTo: 0.9,
          },
        },
      },
    };
  }

  #getYAxisConfig(costs) {
    return {
      show: true,
      tickAmount: 4,
      min: ChartUtils.getMinCost(Math.min(...costs)),
      max: ChartUtils.getMaxCost(Math.max(...costs)),
      opposite: true,
      labels: {
        formatter: val => ChartUtils.formatCurrency(Math.round(val)),
        style: {
          fontSize: ChartUtils.getTailwind("text-xxs"),
          colors: ChartUtils.getTailwind("white"),
        }
      }
    };
  }

}