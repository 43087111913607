import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="policy-switcher"
export default class extends Controller {
    static targets = ["fullPolicy", "limitedPolicy", "checkbox"]

    connect() {
        this.showFullPolicy()
    }

    toggle() {
        if (this.checkboxTarget.checked) {
            this.showLimitedPolicy()
        } else {
            this.showFullPolicy()
        }
    }

    showFullPolicy() {
        this.fullPolicyTarget.classList.remove("hidden")
        this.limitedPolicyTarget.classList.add("hidden")
    }

    showLimitedPolicy() {
        this.fullPolicyTarget.classList.add("hidden")
        this.limitedPolicyTarget.classList.remove("hidden")
    }
}
