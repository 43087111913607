export function restructureServicesCosts(servicesCosts) {
    return servicesCosts.map(({ date, costs }) => ({
        date,
        costs: Object.fromEntries(costs.map(serviceCost => [serviceCost.service, serviceCost.cost]))
    }));
}

export function getMinCost(minCost) {
    const scaledCost = minCost * 0.8;
    const roundedDownCost = Math.floor(scaledCost / 100) * 100;
    return Math.max(roundedDownCost, 0);
}

export function getMaxCost(maxCost) {
    const scaledCost = maxCost * 1.1;
    const roundedUpCost = Math.ceil(scaledCost / 100) * 100;
    return roundedUpCost;
}

export function getTailwind(key) {
    return {
        "primary-600": "#FF7421",
        "gray": "#71717A",
        "white": "#FEFFFF",
        "text-xs": "12px",
        "text-xxs": "10px",
    }[key];
}

export function formatCurrency(value) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 0 }).format(value);
}

export function formatPercentage(value) {
    return new Intl.NumberFormat("en-US", { style: "percent", maximumFractionDigits: 0 }).format(value);
}
